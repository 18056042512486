
.WMI-section-wrapper {
  position: relative;
  background-color: black;
}
.hero-section {
  min-height: 100vh;
  text-align: center;
}

.WMI-hero-heading {
  font-size: 75px;
  font-weight: 700;
}

.hero-subheading {
  line-height: 38px;
  font-size: 30px;
  font-weight: 700;
  margin: 35px 58px;
}

.marketingSeoSearch {
  /* background-color: var(--bg-gray); */
  width: 100%;
  /* position: absolute; */
  height: 72px;
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
}


.seoSearchType{
  padding: 40px;
}

.CircularProgressbar .CircularProgressbar-text { 
  font-size: 15px !important;
}


@media all and (max-width: 1279px) {
  .WMI-hero-heading {
    font-size: 52px;
    line-height: 62px;
  }

  .hero-subheading {
    font-size: 25px;
  }

  .hero-claim-label {
    flex-direction: column;
  }
  .seoSearchType{
    padding: 20px;
  }
}

@media all and (max-width: 767px) {
  .hero-section {
    align-items: flex-start;
    text-align: left;
    min-height: 70vh;
  }
  .marketing-hero-heading {
    font-size: 45px;
    line-height: 47.5px;
    width: 270px;
    margin-top: 60px;
  }
  .hero-subheading {
    font-size: 16px;
    line-height: 22px;
    margin: 35px 0px;
  }
  .hero-claim-label {
    height: 55px;
    font-size: 13px;
  }

  .seoSearchType{
    padding: 5px;
    font-size: 15px;
  }
}
