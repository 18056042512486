

::-webkit-scrollbar {
  display: none;
}

.uiSub{
  font-size: 50px;
    font-weight: 600;

}
.uiUx-hero-section {
  min-height: 100vh;
}


.ui-hero-heading {
  font-size: 106px;
  font-weight: 700;
}

.ui-hero-subheading {
  line-height: 38px;
  font-size: 30px;
  font-weight: 700;
  margin: 35px 58px;
}

#wrapperUiUx {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  background-color: #000000 !important;
      background-image: url("../../../common/Images/UiUxBg.png");
      
    
}

#wrapperUiUx h2 {
  position: absolute;
    left: 44.28px;
    /* text-shadow: 0 0 5px white; */
    color: white;
    width: 40%;
    z-index: 1;
}



#wrapperUiUx #HomePage, #PageA, #PageB, #PageC , #PageD {
  width: 100vw !important;
  height: 100vh !important;
  position: relative;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wrapperUiUx #PageA {
  /* background: url("https://picsum.photos/1920?random=1") center no-repeat; */
  background-color:  #000033;
  background-size: cover;
}

#wrapperUiUx #PageB {
  /* background: url("https://picsum.photos/1920?random=2") center no-repeat; */
  background-color:  #000033;
  background-size: cover;
}

#wrapperUiUx #PageC {
  /* background: url("https://picsum.photos/1920?random=3") center no-repeat; */
  background-color: #000033;
  background-size: cover;
}
#wrapperUiUx #PageD {
  /* background: url("https://picsum.photos/1920?random=4") center no-repeat; */
  background-color:  #000033;
  background-size: cover;
}

#wrapperUiUx #ParallaxDiv {
  position: absolute;
  right: 45.15px;
  width: 733.41px;
  height: 500.41px;
  background: url("../../../common/Images/UserResearch.png") center no-repeat;
  background-size: contain;
  /* background-color: hsl(0, 0%, 60%); */
  /* box-shadow: 0 0 50px black; */
}

#wrapperUiUx #PageB #ParallaxDiv {
 
  background: url("../../../common/Images/ProductDesign.png") center no-repeat;
  background-size: contain;
  
}

#wrapperUiUx #PageC #ParallaxDiv {

  background: url("../../../common/Images/UserTesting.png") center no-repeat;
  background-size: contain;
}
#wrapperUiUx #PageD #ParallaxDiv {
  background: url("../../../common/Images/Stage.png") center no-repeat;
  background-size: contain;
  


}







@media all and (max-width: 767px) {
  
  .ui-hero-heading {
    font-size: 50px;
    font-weight: 900;
  }
  
  .ui-hero-subheading {
    line-height: 38px;
    font-size: 25px;
    font-weight: 700;
    margin: 35px 0px;
  }

  #wrapperUiUx #ParallaxDiv {
    position: absolute;
    right: 20px;
    top: 20%;
    width: 90%;
    height: 500.41px;
    background: url("https://picsum.photos/720?random=5") center no-repeat;
    background-size: cover;
    background-color: hsl(0, 0%, 60%);
    box-shadow: 0 0 50px black;
  }


  #wrapperUiUx h2 {
    position: absolute;
      left: 44.28px;
      text-shadow: 0 0 5px white;
      color: white;
      width: 80%;
      z-index: 1;
  }

}