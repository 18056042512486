.feel-special {
  background-color: var(--bg-red);
  background-image: url("../../common/Images/2nd.png");
}

@media all and (max-width: 1279px) {
  .feel-special {
    background-position: center bottom;
  }
}

@media all and (max-width: 767px) {
  .feel-special {
    background-image: url("../../common/Images/2nd.png");
    /* min-height: 1150px; */
    background-position: right center;
  }
}

