.AboutUs-hero-section-wrapper{
    position: relative;
    /* background-color: var(--bg-black) !important; */
    background-image: url("../../common/Images/aboutUs.png");
    background-repeat: no-repeat;
    background-size: cover;
 
  }
  .hero-section {
    min-height: 150vh;
    
  }
  
  .about-hero-heading {
    font-size: 60px;
    font-weight: 500;
    width: 100%;
    text-align: initial;
    margin-bottom: 5%;

  }
  
  .AboutContent{
    /* height: 100vh; */
    background-color: #1C181E;
  }

  .AboutContentDiv{
    padding: 10%;
  }
 
  
  

  @media all and (max-width: 1279px) {
    .hero-heading {
        font-size: 55px;
        line-height: 52.5px;
        width: 100%;
        margin-top: 60px;
    }
  
 
  
  }
  
  @media all and (max-width: 767px) {
    .hero-section {
      align-items: flex-start;
      text-align: left;
      min-height: 120vh !important;
    } 
    .about-hero-heading {
      font-size: 55px;
      line-height: 47.5px;
      width: 270px;
      margin-top: 60px;
    }
   
  }
  