.contactUsBg
{
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
}
.contact-container{
   
    margin-left: 5%;
    margin-bottom: 5%;
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    width: 900px;
    max-width: 90%;
    box-shadow:  9px 9px 18px #cdcdcd,              -9px -9px 18px #ffffff;
}
/* Left side Design*/
.contact-left
{
    width: 60%;
    padding: 20px;
    color: #52565e;
}
.contact-form
{
    margin-top: 10px;
    width: 100%;
}
.single-row
{
    display: flex;
    justify-content: space-between;
}
.form-in
{
    padding: 0.5em;
    font-size: 14px;
    width: 100%;
    resize: vertical;
    margin-top: 1em;
    background: #eeeeee;
    border: none;
    box-shadow: inset 5px 5px 12px #e0e0e0, 
            inset -5px -5px 12px #fcfcfc;
            outline: none;
    color: #52565e;
}
.single-row + input
{
margin: 0 10px;
}
.ml
{
    margin-left: 10px;
}
.mr
{
    margin-right: 10px;
}

.multiple-row
{
    position: relative;
}
#submit
{
    display: none;
}
#submit + label
{
    position: absolute;
    font-size: 14px;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    background-color: #3369e7;
    border-radius: 100%;
    color: #eeeeee;
    box-shadow:  6px 6px 12px #bebebe, 
             -6px -6px 12px #ffffff;
    cursor: pointer;
}

#submit + label:hover
{
    box-shadow: inset 6px 6px 12px #2954b9, 
            inset -6px -6px 12px #3d7eff;
}
/* Right side Design*/
.contact-right
{
    padding: 20px;
    width: 40%;
    background: linear-gradient(#3369e798,#3369e798),url('https://images.unsplash.com/photo-1597773026935-df49538167e4?ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80');
    color: #eeeeee;
}
textarea
{
    min-height: 200px;
}

.contact-info,.social-links
{
font-size: 14px;
margin-top: 40px;
}
.contact-info p
{
    margin-bottom: 20px;
}
.contact-info p  i
{
    margin-right: 20px;
}

.social-links a
{
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    color: #e7e7e7;
    margin-right: 20px;
    transition: 0.3s;
}
.social-links a:hover
{
font-size: 22px;
color: #ffffff;
}


@media only screen and (max-width: 770px)
{
    .contact-container
    {
        flex-direction: column;
    }
    .contact-right,.contact-left
    {
        width: 100%;
    }
}


.modal-dialog {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
}

.modal-content {
    position: relative;
    display: flex;
    background-color: #eeeeee !important;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}
