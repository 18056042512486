.Section1{
    position: relative;
    /* background-color: var(--bg-black) !important; */
    height: 90vh;
    background-image: url("../../../common/Images/photoMain1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .Section1Text{
    margin: auto;
    position: absolute;
    top: 35%;
    width: 40%;
    right: 10%;
}





.Section2{
    position: relative;
    /* background-color: var(--bg-black) !important; */
    height: 90vh;
    background-image: url("../../../common/Images/photoMain2.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .Section2Text{
    margin: auto;
    position: absolute;
    top: 35%;
    width: 40%;
    left: 10%;
}


.Section3{
    position: relative;
    /* background-color: var(--bg-black) !important; */
    height: 90vh;
    background-image: url("../../../common/Images/photoMain3.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .Section3Text{
    margin: auto;
    position: absolute;
    top: 35%;
    width: 40%;
    right: 10%;
}


.Section4{
    position: relative;
    /* background-color: var(--bg-black) !important; */
    height: 90vh;
    background-image: url("../../../common/Images/photoMain4.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .Section4Text{
    margin: auto;
    position: absolute;
    top: 35%;
    width: 40%;
    left: 10%;
}