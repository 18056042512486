.SeoBg-wrapper  {
  
    position: relative;
  background-color: var(--bg-black) !important;
  background-image: url("../../../common/Images/SeoHero.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 80px;
  }

  .SeoHeading{
    font-weight: bold;
  }
  .mainHeading{
    margin-left: 20px;
  }
.subSeoHeading{
  margin-top: 30px;
  margin-bottom: 60px;
}


  /* .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    
    padding: 10px;
    margin-top: 100px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 40%);
    padding: 20px;
    font-size: 30px;
    margin: 5px;

    text-align: center;
  } */


  

  
  
  /* Screen larger than 600px? 2 column */
  @media (min-width: 600px) {
    .cards { grid-template-columns: repeat(2, 1fr); }
  }
  
  /* Screen larger than 900px? 3 columns */
  @media (min-width: 900px) {
    .cards { grid-template-columns: repeat(2, 1fr); }
  }
  