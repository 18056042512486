.LetsStartButton-wrapper {
    cursor: pointer;
    background-color: #010672;
    color: white;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 65px;
    border-radius: 40px;
    width: max-content;
  }
  
  @media all and (max-width: 767px) {
    .LetsStartButton-wrapper {
      font-size: 18px;
      padding: 16px 24px;
    }
  }
  

.app-rating {
    padding: 100px 0px 30px;
    justify-content: space-around;
    background-color: #fff;
    
  }
  
  .app-rating-value {
    font-size: 160px;
    line-height: 120px;
    padding-right: 16px;
    font-weight: 700;
  }
  
  .app-rating-stars {
    width: 179px;
    height: 28px;
  }
  
  .LetsStartHeading {
    font-size: 60px;
    line-height: 55px;
    color: #22D1B4;
    padding-left: 16px;
    font-weight: 700;
  }
  
  .app-rating-block {
    margin-bottom: 70px;
  }
  
  .app-rating-icon {
    height: 30px;
    margin-right: 6px;
  }
  
  .app-rating-button {
    padding: 15px 30px !important;
    font-size: 20px;
    margin-top: 40px;
  }
  
  @media all and (max-width: 1279px) {
    .app-rating {
      padding: 100px 0px;
      background-color: #fff;
    }
    .app-rating-value {
      font-size: 100px;
    }
    .app-rating-stars {
      width: 120px;
      height: 18px;
    }
    .LetsStartHeading {
      font-size: 45px;
      line-height: 45px;
    }
  }
  
  @media all and (max-width: 767px) {
    .app-rating {
      padding: 70px 0px;
      flex-direction: column;
    }
    .app-rating-value {
      font-size: 90px;
    }
    .LetsStartHeading {
      font-size: 31px;
      line-height: 32px;
      text-align: left;
      align-self: center;
    }
  }
  