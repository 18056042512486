.product-showcase {
  width: 100vw;
  /* height: 130vh; */
  position: relative;
  overflow: hidden;
  /* background-image: url(https://web-images.credcdn.in/_next/assets/images/home-page/video-bg.png);
 
  background-size: cover; */
  background-position: right top;
  background-color: white;
}

.showcase-wrapper {
  min-height: 70%;
}

.showcase-ui {
  position: absolute;
  bottom: 45px;
  left: 50%;
}

.showcase-mockup-1 {
  opacity: 1;
  transform: translate(-160%, 25%);
  z-index: 98;
}

.showcase-mockup-2 {
  opacity: 1;
  transform: translate(-105%, 15%);
  z-index: 99;
}
.showcase-mockup-3 {
  opacity: 1;
  transform: translate(-50%, 5%);
  z-index: 100;
}
.showcase-mockup-4 {
  opacity: 1;
  transform: translate(5%, 15%);
  z-index: 99;
}
.showcase-mockup-5 {
  opacity: 1;
  transform: translate(60%, 25%);
  z-index: 98;
}

@media all and (max-width: 1279px) {
  .product-showcase {
    /* height: 620px; */
  }
  .showcase-ui {
    width: 300px;
  }
}

@media all and (max-width: 767px) {
  .product-showcase {
    /* height: 450px; */
    /* background-image: url(https://web-images.credcdn.in/_next/assets/images/home-page/video-bg-mobile.png); */
    background-color: white;
  }
  .showcase-ui {
    width: 170px;
  }
}



.show-for-print-only {
  display: none;
}



.Productbox1 {
  background: #4CBE99;
  background-image: url('../../common/Images/divsBG/box1.png');
  background-color: white;
  background-size: cover;
  width: 102%;
  height: 100%;
  margin-bottom: 18rem;
  padding: 1rem 1rem;
}

.Productbox2 {
  background: #4CBE99;
  background-image: url('../../common/Images/divsBG/box2.png');
  background-color: white;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin-bottom: 18rem;
  padding: 1rem 1rem;
}
.Productbox3 {
  background: #4CBE99;
  background-image: url('../../common/Images/divsBG/box3.png');
  background-color: white;
  background-size: cover;
  width: 102%;
  height: 100%;
  margin-bottom: 18rem;
  padding: 1rem 1rem;
}
.Productbox4 {
  background: #4CBE99;
  background-image: url('../../common/Images/divsBG/box4.png');
  background-color: white;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin-bottom: 18rem;
  padding: 1rem 1rem;
}