.Event-hero-section-wrapper{
  position: relative;
  /* background-color: var(--bg-black) !important; */
  background-image: url("../../../common/Images/13.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
#wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

#wrapper h1 {
  position: absolute;
  left: var(--GoldenRatio7);
  font-size: var(--FontH2);
}

#wrapper h1 span {
  color: var(--ColorDarkB);
}

#wrapper p {
  position: absolute;
  bottom: var(--GoldenRatio7);
  color: var(--ColorDarkB);
  font-size: var(--FontParagraph);
}

#ImgWrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  perspective: 2200px;
  background: var(--ColorDarkA);
}

#ImgWrapper img {
  position: absolute;
  box-shadow: 0 0 31px black;
}

#ImgWrapper #img1 {
  z-index: 1;
  height: 50vh;
  bottom: 15%;
  left: 5%;
}

#ImgWrapper #img2 {
  z-index: 2;
  height: 45vh;
  bottom: 40%;
  right: 5%;
}

#ImgWrapper #img3 {
  z-index: 3;
  height: 40vh;
  bottom: 22%;
  right: 25%;
}

#ImgWrapper #img4 {
  z-index: 4;
  height: 31vh;
  bottom: 55%;
  left: 13%;
}

#ImgWrapper #img5 {
  z-index: 5;
  height: 50vh;
  bottom: 22%;
  left: 36%;
}

#ImgWrapper #img6 {
  z-index: 6;
  height: 31vh;
  bottom: 13%;
  left: 13%;
}

#ImgWrapper #img7 {
  z-index: 7;
  height: 31vh;
  bottom: 10%;
  right: 13%;
}

#codeby {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#codeby a {
  text-decoration: none;
  font-size: 40px;
  font-weight: 400;
  
}

#codeby a span {
  font-weight: 700;
  display: inline-block;
 
}

/*-------------@media (max-width: 1920px)------------- */


@media (max-width: 400px) {
  
  #wrapper h1 {
    /* font-size: var(--FontH5); */
  }
  #ImgWrapper #img1 {
    height: 22vh;
    bottom: 50%;
    left: 7%;
  }
  #ImgWrapper #img2 {
    height: 22vh;
    bottom: 70%;
    right: 7%;
  }
  #ImgWrapper #img3 {
    height: 22vh;
    bottom: 35%;
    right: 35%;
  }
  #ImgWrapper #img4 {
    height: 22vh;
    bottom: 61.89%;
    left: 13%;
  }
  #ImgWrapper #img5 {
    height: 35vh;
    left: 46%;
    bottom: 31%;
  }
  #ImgWrapper #img6 {
    height: 17vh;
    bottom: 22%;
  }
  #ImgWrapper #img7 {
    height: 18vh;
    left: 22%;
  }
  #codeby a {
    
  }
}