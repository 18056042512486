/* .hero-section-wrapper {
  position: relative;
} */
.marketing-nav{
	position: sticky;
	top: 20%;
	bottom: auto;
	z-index: 10;
}

.marketing-nav a {
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.5em;
}

ul{
	list-style: none;
	padding: 0;
}
li{
	padding: 10px 0;
}
span{
	display: inline-block;
	position:relative;
}
marketingNav a{
	display: inline-block;
	color: #ffffff;
	text-decoration: none;
	font-size: 2em;
}
.circle{
	height: 10px;
	width: 10px;
	left: -10px;
	border-radius: 50%;
	background-color: #272727;
}
.rect{
	height: 3px;
	width: 0px;
	left: 0;
	bottom: 4px;
	background-color: #272727;
	-webkit-transition: -webkit-transform 0.6s, width 1s;
	-moz-transition: -webkit-transform 0.6s, width 1s;
	transition: transform 0.6s, width 1s;
}
.marketing-nav a:hover, .marketing-nav .active-section{
	color: #ffffff;
}
.marketing-nav a:hover span, .marketing-nav .active-section span{
	background-color: #ffffff;
}
.marketing-nav .active-section .rect{
	width: 40px;
}

.LeadGeneration{
	position: relative;
	
	background-color: #7074D7 !important;
	background-repeat: no-repeat;
	background-size: cover;
}
.SearchAds{
	position: relative;
	background-color: black; 
	background-image: linear-gradient(to right, #AC6350 , #DB9281);
	background-repeat: no-repeat;
	background-size: cover;
}
.DisplayAds{
	position: relative;
	background-color: #7074D7 !important;
	/* background-image: url("../../../common/Images/9th.jpg"); */
	background-repeat: no-repeat;
	background-size: cover;
}

.VideoAds{
	position: relative;
	background-color: black; 
	background-image: linear-gradient(to right, #AC6350 , #DB9281);
	background-repeat: no-repeat;
	background-size: cover;
}

.CreativesOnUs{
	position: relative;
	background-color: #7074D7 !important;
	background-repeat: no-repeat;
	background-size: cover;
}
.DMimg{
	height: 65%;
	padding-top: 15%

}
.XYZ{
	height: 45%;
}
.content-section{
	margin-bottom: 30px;
	position: relative;
	width: 75%;
	height: 80vh;
	left: 60%;
	background-color: #ecf1f1;
	text-align: center;
	-webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.content-section h1{
	position: relative;
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
	color:#fff;
	font-size: 3em;
}
.content-section h2{
	position: relative;
	top: 58%;
	left: 50%;
	padding: 50px;
	-webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
	color:#fff;
	font-size: 1.8em;
}

/*CREDITS*/
.about{
	position: fixed;
	bottom:0; 
	left: 1%;
}
.about a{
	text-decoration: none;
	font-size: 1.5em;
}
.about a:visited, .about a:active, .about a:link{
	color:#000;
}
.about a:hover{
	color: red;
}

@media all and (max-width: 1279px) {

	.marketing-nav a{
		display: inline-block;
		color: #ffffff;
		text-decoration: none;
		font-size: 1.5em;
	}

  }
  
  @media all and (max-width: 767px) {

	.marketing-nav a{
		display: inline-block;
		color: #ffffff;
		text-decoration: none;
		font-size: 1em;
		display: none;
	}

	.content-section{
		position: relative;
		width: 100%;
		height: 80vh;
		left: 50%;
		background-color: #ecf1f1;
		text-align: center;
		-webkit-transform: translateX(-50%);
	  -moz-transform: translateX(-50%);
	  -ms-transform: translateX(-50%);
	  -o-transform: translateX(-50%);
	  transform: translateX(-50%);
	}
	.marketing-nav {
		display: none;
	}
	.content-section h1{
		position: relative;
		top: 50%;
		left: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%);
	  -moz-transform: translateX(-50%) translateY(-50%);
	  -ms-transform: translateX(-50%) translateY(-50%);
	  -o-transform: translateX(-50%) translateY(-50%);
	  transform: translateX(-50%) translateY(-50%);
		color:#fff;
		font-size: 2em;
	}
	.content-section h2{
		position: relative;
		top: 78%;
		left: 50%;
		padding: 50px;
		-webkit-transform: translateX(-50%) translateY(-50%);
	  -moz-transform: translateX(-50%) translateY(-50%);
	  -ms-transform: translateX(-50%) translateY(-50%);
	  -o-transform: translateX(-50%) translateY(-50%);
	  transform: translateX(-50%) translateY(-50%);
		color:#fff;
		font-size: 1.2em;
	}
	
	
  }


  .marketingHeight{
	height: 500px;
  }