.OfflineMarketing {
  background-color: #FFF5F1;

  /* background-image: url('../../common/Images/5th.jpg'); */
  background-size: cover;
  background-position: right bottom;
  position: relative;
}

.story-wrapper {
  align-items: center;
}

.event-story-heading-wrapper {
  width: 50%;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.event-story-heading {
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  max-width: 80%;
}

.event-story-detail {
  width: 75%;
  font-weight: 500;
  margin-left: 60px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.event-story-para {
  font-size: 18px;
  line-height: 28px;
  margin-top: 20px;
  max-width: 550px;
}


.EventImg{
  margin-top: 60px;
  margin-left: 110px;
  margin-bottom: 80px;
  }

@media all and (max-width: 1279px) {
  .event-story-heading {
    max-width: 100%;
  }
}

@media all and (max-width: 767px) {
  /* .event-story {
    background-image: url("../../common/Images/5th.jpg");
  } */

  .event-story-heading {
    font-size: 55px;
    line-height: 55px;
    padding-bottom: 80px;
  }

  .event-story-para {
    font-size: 15px;
  }

  .story-wrapper {
    flex-direction: column;
    padding: 70px 0px;
  }

  .event-story-heading-wrapper {
    width: 100%;
  }

  .event-story-detail {
    width: 100%;
    margin-left: 0px;
  }

  .EventImg{
    margin-left: 0px;
  }
 

}