.max-width {
  margin: auto;
  max-width: 90%;
}

.width-50 {
  width: 50%;
}
.flex {
  display: flex;
}

.absolute-center {
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.photo-section {
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
}

.photo-section-child {
  padding: 100px 0px 50px;
}

.photo-section-heading {
  font-size: 60px;
  line-height: 180px;
  font-weight: 700;
  max-width: 700px;
  color: #FFF
}

.photo-section-subheading {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  max-width: 630px;
  margin-top: 16px;
}

.photo-section-description {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  max-width: 630px;
  margin-bottom: 40px;
  /* margin-top: 200px; */
  margin-top: 10px;
  color: #fff

}
@media all and (max-width: 767px) {
  .photo-section-description {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    max-width: 630px;
    margin-bottom: 40px;
    /* margin-top: 200px; */
    margin-top: 100px;
    color: #fff
  
  }
}


@media all and (max-width: 1279px) {
  .max-width {
    max-width: 80%;
  }
  .photo-section-heading {
    max-width: 670px;
  }

  .photo-section-child {
    padding: 100px 0px;
  }

}

@media all and (max-width: 767px) {
  .max-width {
    max-width: 90%;
  }

  .non-mobile {
    display: none !important;
  }

  .photo-section-heading {
    font-size: 65px;
    line-height: 58px;
  }
  .photo-section-subheading {
    font-size: 18px;
    line-height: 24px;
    margin-top: 26px;
  }
  .photo-section-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* margin-top: 500px; */
  }

  .photo-section-child {
    padding: 195px 0px 90px;
  }

 
}

@media all and (min-width: 766px) {
  .only-mobile {
    display: none !important;
  }
}
