
.SMMhero-section-wrapper {
  position: relative;
  background-color: #000033 !important;
  min-height: 80vh;
  padding: 80px;
}
.hero-section {
  min-height: 100vh;
  text-align: center;
}

.marketing-hero-heading {
  font-size: 75px;
  font-weight: 700;
  color: #000;
  
}

.card {
  background-color: rgba(255, 255, 255, 40%);
  padding: 20px;
  font-size: 30px;
  height: 130px;
  margin: 5px;
  
  text-align: center;
}

.cards {
  max-width: 1200px;
  margin: 50px auto;
  display: grid;
  grid-gap: 1rem;
}

/* .hero-subheading {
  line-height: 38px;
  font-size: 30px;
  font-weight: 700;
  margin: 35px 58px;
  color: #000;

} */

.marketingSMMSearch {
  /* background-color: var(--bg-gray); */
  width: 100%;
  /* position: absolute; */
  height: 72px;
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
    color: #000;

}


.seoSearchType{
  padding: 40px;
}

.CircularProgressbar .CircularProgressbar-text { 
  font-size: 15px !important;
}
.socialMediaIcons{
  width: 500px;
}

.socialMediaIconsAlignment{
  justify-content: space-around;
  position: relative;
  left: -100px;
}



@media all and (max-width: 1279px) {
  .marketing-hero-heading {
    font-size: 52px;
    line-height: 92px;
  }

  .hero-subheading {
    font-size: 25px;
  }

  .hero-claim-label {
    flex-direction: column;
  }
  .seoSearchType{
    padding: 20px;
  }
  .socialMediaIcons{
    width: 200px;
  }
  .socialMediaIconsAlignment{
    justify-content: space-around;
    position: relative;
    left: -100px;
  }
}

@media all and (max-width: 767px) {
  .hero-section {
    align-items: flex-start;
    text-align: left;
    min-height: 70vh;
  }
  .marketing-hero-heading {
    font-size: 45px;
    line-height: 47.5px;
    width: 270px;
    margin-top: 60px;
  }
  .hero-subheading {
    font-size: 16px;
    line-height: 22px;
    margin: 35px 0px;
  }
  .hero-claim-label {
    height: 55px;
    font-size: 13px;
  }

  .seoSearchType{
    padding: 5px;
    font-size: 15px;
  }
  .socialMediaIcons{
    width: 100px;
  }
  .socialMediaIconsAlignment{
    justify-content: space-around;
    position: relative;
    left: -45px;
  }
}
