/* .Event-hero-section-wrapper {
  position: relative;
  background-color: var(--bg-black) !important;
  background-image: url("../../../common/Images/13.jpg");
  background-repeat: no-repeat;
  background-size: cover;
} */
.hero-section {
  min-height: 100vh;
  text-align: center;
}

.hero-heading {
  font-size: 106px;
  font-weight: 700;
}

.hero-subheading {
  line-height: 38px;
  font-size: 30px;
  font-weight: 700;
  margin: 35px 58px;
}

.hero-claim-label {
  background-color: var(--bg-gray);
  width: 100%;
  position: absolute;
  height: 72px;
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
}

.claim-arrow {
  height: 11px;
  margin: 7px 0px 0px 8px;
}

.claim-anchor {
  margin-left: 17px;
  color: var(--link-blue);
}

@media all and (max-width: 1279px) {
  .hero-heading {
    font-size: 82px;
    line-height: 92px;
  }

  .hero-subheading {
    font-size: 25px;
  }

  .hero-claim-label {
    flex-direction: column;
  }
  .claim-anchor {
    margin-left: 0px;
  }
}

@media all and (max-width: 767px) {
  .hero-section {
    align-items: flex-start;
    text-align: left;
    min-height: 70vh;
  }
  .hero-heading {
    font-size: 55px;
    line-height: 47.5px;
    width: 270px;
    margin-top: 60px;
  }
  .hero-subheading {
    font-size: 16px;
    line-height: 22px;
    margin: 35px 0px;
  }
  .hero-claim-label {
    height: 55px;
    font-size: 13px;
  }
  .claim-arrow {
    height: 6px;
    margin: 2px 0px 0px 3px;
  }

  .text {
    font-family: "Montserrat", sans-serif;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    position: fixed;
    top: 30% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    height: 10%;
    width: 90% !important;
    margin: auto;
    display: none;
  }
}
 

.div-container {
  position: relative;
  top: 0;
  height: 100vh;
  width: 100%;
  margin: 0;
  z-index: 1;
  text-align: center;
}

.parallax {
  position: relative;
  /* Full height */
  height: 100%;
  margin: 0;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-height: 100%;
  width: 100%;
}

.parallax1 {
  background-image: url("../../../common/Images/BP3.jpg");
  /* max-height: 100%;
  width: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax2 {
  background-image: url("../../../common/Images/marrige.jpg");
  max-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax3 {
  background-image: url("../../../common/Images/img1.jpg");
  max-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax4 {
  background-image: url("../../../common/Images/Corporate.jpg");
  max-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.text {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  position: fixed;
  top: 45%;
  left: 30%;
  transform: translate(-50%, -50%);
  height: 10%;
  width: 50%;
  margin: auto;
  display: none;
}


