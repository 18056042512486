.cred-security {
  background-color: var(--bg-purple);
  background-image: url('../../common/Images/UI_UX.png');
  background-repeat: no-repeat;
    background-size: cover;
}

@media all and (max-width: 1280px) {
  .cred-security {
    background-position: center bottom;
  }
}

@media all and (max-width: 768px) {
  .cred-security {
    background-image: url('../../common/Images/UI_UX.png');
    /* min-height: 1150px; */
    background-position: left center;
  }
}
