.OurClientBg-wrapper  {
    position: relative;
    background: linear-gradient(0deg, rgba(62,191,239,1) 0%,   rgba(53,245,198,1) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
    
  }




  /* .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    
    padding: 10px;
    margin-top: 100px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 40%);
    padding: 20px;
    font-size: 30px;
    margin: 5px;

    text-align: center;
  } */


  .clientTestimonial{
    height: 300px;
    width: 400px;
    background: white;
    border-radius: 15px;
    padding: 30px;
    margin-top:55px;
  }

  .clientName{
    font-size: 22px;
    font-weight: bold;
    color: black;
    margin-top: 10px;
  }
  .clientDis{
    text-align: left !important;
    font-size: 20px;
    color: #595959;
    margin-top: 30px;
  }

  .card {
    background-color: rgba(255, 255, 255, 40%);
    padding: 20px;
    font-size: 30px;
    margin: 5px;
    text-align: center;
  }
  
  .cards {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
  }
  
  /* Screen larger than 600px? 2 column */
  @media (min-width: 600px) {
    .cards { grid-template-columns: repeat(2, 1fr); }
  }
  
  /* Screen larger than 900px? 3 columns */
  @media (min-width: 900px) {
    .cards { grid-template-columns: repeat(3, 1fr); }
  }
  