/* Style the navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  color: #fff;
  font-size: 1.2rem;
  z-index: 11;
  position: fixed;
  width: 100%;

  background: rgba(255, 255, 255, .5) !important;
  backdrop-filter: blur(8px);

}


.nav-link {
  color: black;
  font-weight: 500;
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  /* font-weight: var(--bs-nav-link-font-weight); */
  /* color: var(--bs-nav-link-color); */
  /* text-decoration: none; */
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.navbar-brand {

  display: inline-flex ;
}

.navbar-toggle.active {
  background-color: #555;
}


.navbar:hover {
  background-color: #555;
}

/* Style the logo */
.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Style the menu */
.navbar-menu {
  display: flex;
}

.navbar-menu a {
  margin-left: 20px;
  text-decoration: none;
  color: #fff;
}

/* Style the toggle button for small screens */
.navbar-toggle {
  display: none; /* Hide the toggle button by default */
}

/* Style the menu when the toggle button is clicked */
.navbar-menu.active {
  display: block;
}

.dropdown-menu {
  background-color: #ffffffd2 !important;
}

.dropdown-item {
  font-size: inherit;
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 500;
  color: black;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
 
  border: 0;
}


.dropdown-item:focus, .dropdown-item:hover {
  color: #010672;
  font-weight: 700;
  /* background-color: var(--bs-dropdown-link-hover-bg); */
  background-color: transparent !important;

}
/* Style the toggle button when it is clicked */


.nav-link:hover {
  color: #010672 !important;
}
.nav-link:focus {
  color: #010672 !important;
}
.contactUsBtn{
width: 136px;
height: 42px;
left: 1216px;
top: 40px;
background: #010672;
border-radius: 6px;
border: transparent;
}

/* Media query to show the toggle button on small screens */
@media (max-width: 768px) {
  .navbar-menu {
    display: none; /* Hide the menu by default */
  }
  .navbar-toggle {
    display: block; /* Show the toggle button */
  }
  .navList{
    display: none;
  }
  
}



@media (min-width: 1228px) {
 .DoNotWant{
  display: none;
 }
  
}
