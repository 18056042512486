.MarketingHome {
  background-color: #010672;
  min-height: 75vh;
  /* background-image: url('../../common/Images/5th.jpg'); */
  background-size: cover;
  background-position: right bottom;
  position: relative;
}

.story-wrapper {
  align-items: center;
}

.cred-story-heading-wrapper {
  width: 50%;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.cred-story-heading {
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  max-width: 80%;
}

.cred-story-detail {
  width: 50%;
  font-weight: 500;
  margin-left: 0px;
  margin-top: -30px;
}

.cred-story-para {
  font-size: 18px;
  line-height: 28px;
  margin-top: 20px;
  max-width: 550px;
}

@media all and (max-width: 1279px) {
  .cred-story-heading {
    max-width: 100%;
  }
}

@media all and (max-width: 767px) {
  .cred-story {
    /* background-image: url("../../common/Images/5th.jpg"); */
  }

  .cred-story-heading {
    font-size: 55px;
    line-height: 57px;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .cred-story-para {
    font-size: 15px;
  }

  .story-wrapper {
    flex-direction: column;
    padding: 70px 0px;
  }

  .cred-story-heading-wrapper {
    width: 100%;
  }

  .cred-story-detail {
    width: 100%;
  }
}
